<template>
  <app-page>
    <app-checkout-main-nav></app-checkout-main-nav>
    <ui-alert type="error" v-if="error">{{$t('error')}}</ui-alert>
      <div v-if="!isLoading && !error" class="checkout container">
        <app-checkout-nav v-if="checkout.currentStatus.id !== 70"></app-checkout-nav>
        <app-checkout-screen></app-checkout-screen>
        <app-checkout-footer></app-checkout-footer>
      </div>
      <app-checkout-information v-if="!isLoading && !error"></app-checkout-information>
      <app-checkout-receipt v-if="!isLoading && !error"></app-checkout-receipt>
  </app-page>
</template>

<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import '../scss/app_desktop.scss'

import Nav from "@/desktopComponents/Checkout/Nav";
import Screen from "@/desktopComponents/Checkout/Screen";
import Footer from "@/desktopComponents/Checkout/Footer";
import Receipt from "@/desktopComponents/Checkout/Receipt";
import Informations from "@/desktopComponents/Checkout/Informations";
import CheckoutNav from "@/desktopComponents/Checkout/CheckoutNav";
import {initCrisp} from "@/crisp";


export default {
  components:{
    'app-checkout-nav': Nav,
    'app-checkout-main-nav': CheckoutNav,
    'app-checkout-screen': Screen,
    'app-checkout-footer': Footer,
    'app-checkout-receipt': Receipt,
    'app-checkout-information': Informations
  },
  metaInfo(){
    return {
      title: this.isLoading ? '' : this.checkout.merchant.name,
      titleTemplate: '%s | Suivi de votre commande Delicity'
    }
  },
  methods: {
    ...mapActions(['loadCheckout']),
    load(){
      this.loadCheckout({
        merchantSlug: this.$route.params.merchantSlug ? this.$route.params.merchantSlug : location.host,
        orderToken: this.$route.params.orderToken,
        shareToken: this.$route.params.shareToken
      }).then(() => {

        if(this.checkout.currentUserLogged){
          initCrisp(
              this.checkout.receipt.client.email,
              this.checkout.receipt.client.name,
              this.checkout.receipt.client.id,
              this.checkout.orderId
          );
        }

        this.shareInUrl();
      });
    },
    shareInUrl(){
      if(!this.$route.params.shareToken && this.shareToken){
        this.$router.push({to: 'checkout', params: {
            merchantSlug:this.checkout.merchant.slug,
            orderToken:this.checkout.orderToken,
            shareToken:this.shareToken
          }});
      }
    }
  },
  computed: {
    ...mapGetters(['checkout', 'error', 'shareToken']),
    isLoading(){
      return !(this.checkout !== null);
    },
  },
  async mounted() {


    if(!this.checkout){
      this.load();
    }
    else{
      this.shareInUrl();
    }

    if(!localStorage.getItem('bearer')){
      setInterval(() => {
        this.load();
      }, 10000);
    }
    else{
      this.$nextTick(() => {
        Echo.private('order.' + this.$route.params.orderToken)
            .listen('CheckoutUpdate', (e) => {
              this.load();
            });
      });
      setInterval(() => {
        this.load();
      }, 10000);
    }
  },
}
</script>
