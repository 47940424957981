<template>
  <app-panel type="panel"
             class="address-list-modal"
             event-name="openReceipt"
             :title="$t('receipt')"
             hide-validate-button>

    <div class="receipt-container">
      <div class="receipt-heading">
        <h1 v-if="checkout.receipt.client">{{$t('receipt_title', {clientName: checkout.receipt.client.firstname})}}</h1>
        <h6>{{ $t('receipt_subtitle', {merchantName: checkout.merchant.name}) }}</h6>
        <span>{{checkout.orderRef}} • {{ $t('receipt_checkout_at', {dateFormated: checkout.receipt.checkoutAt})}}</span>
      </div>

      <div class="receipt-product" v-for="product in checkout.receipt.products">
        <div class="quantity">
          <span>{{product.quantity}}</span>
        </div>
        <div class="product">
          <strong>{{ product.name }}</strong>
          <span v-if="product.optionParsed">{{product.optionParsed}}</span>
          <span v-if="product.comment">{{$t('comment')}}: {{product.comment}}</span>
        </div>
        <div class="price">
          <strong>{{product.price.price}}</strong>
        </div>
      </div>

      <hr>

      <div class="price-row">
        <strong>{{$t('receipt_sub_total')}}</strong>
        <strong>{{checkout.receipt.values.subTotal.price}}</strong>
      </div>
      <div class="price-row">
        <span>{{$t('receipt_delivery_fees')}}</span>
        <span>{{checkout.receipt.values.deliveryFees.price}}</span>
      </div>

      <div class="price-row" v-if="checkout.receipt.values.merchantSmallOrderSupplement.value !== 0">
        <span>+ {{$t('cart_price_small_order_supplement')}}</span>
        <span>{{checkout.receipt.values.merchantSmallOrderSupplement.price}}</span>
      </div>
      <div class="price-row" v-if="checkout.receipt.values.merchantSmallOrderSupplement.value !== 0">
        <span>+ {{$t('cart_price_long_distance_supplement')}}</span>
        <span>{{checkout.receipt.values.merchantLongDistanceSupplement.price}}</span>
      </div>

      <div class="price-row">
        <span>{{$t('receipt_platform_fees')}}</span>
        <span>{{checkout.receipt.values.platformFees.price}}</span>
      </div>
      <div class="price-row" v-if="checkout.receipt.values.discount.value !== 0">
        <span>{{$t('receipt_discount')}}</span>
        <span>{{checkout.receipt.values.discount.price}}</span>
      </div>
      <div class="price-row-discount" v-for="discount in checkout.discount" v-if="discount.value.value !== 0 || discount.type === 'receipt'">
        <span>{{discount.message}}</span>
        <span v-if="discount.value.value > 0">-{{discount.value.price}}</span>
      </div>


      <div class="price-row">
        <strong>{{$t('receipt_total')}}</strong>
        <strong>{{checkout.receipt.values.total.price}}</strong>
      </div>
      <hr>
      <div class="price-row">
        <strong>{{ $t('receipt_total_billed') }}</strong>
      </div>
      <div class="price-row" v-for="payment in checkout.receipt.payments">
        <span>{{ $t('receipt_card', {brand: payment.cardBrand, last4: payment.cardLast4})}}</span>
        <strong>{{ payment.price.price }}</strong>
      </div>
      <div class="price-row">
        <span>{{ $t('receive_invoice_after_delivery') }}</span>
      </div>

    </div>


  </app-panel>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import PanelTemplate from "@/desktopComponents/PanelTemplate";

export default {
  components: {
    'app-panel': PanelTemplate
  },
  computed: {
    ...mapGetters(['checkout'])
  },
}
</script>
