<template>
  <div class="checkout-footer">
    <!--<ui-button color="clear" @click="$root.$emit('openInformations')">{{ $t('more_informations') }}</ui-button>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions([]),
  },
  computed: {
    ...mapGetters(['checkout']),
  }
}
</script>
