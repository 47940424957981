<template>
  <nav class="main-nav" v-if="checkout">
    <div class="container nav-content">
      <router-link :to="{name:'merchant', params:{merchantSlug:$route.params.merchantSlug}}" class="merchant">
        <img :src="checkout.merchant.logo.path" alt="">
        <div class="info">
          <strong>{{checkout.merchant.name}}</strong>
          <span>{{checkout.merchant.subtitle}}</span>
        </div>
      </router-link>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['checkout'])
  }
}
</script>
