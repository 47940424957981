<template>
  <div class="checkout-screen">

    <div class="checkout-image" v-if="checkout.currentStatus.status !== 'delivery_ongoing' || !this.checkout.courier">
      <strong class="title" v-if="checkout.currentStatus.status === 'delivered'">{{$t('good_appetit')}}</strong>

      <strong class="title" v-if="checkout.currentStatus.status === 'cancel'">{{$t('checkout_cancel_subtitle', {triggerBy: checkout.cancel.triggerBy, reasonFormated: checkout.cancel.reasonFormated})}}</strong>
      <p v-if="checkout.currentStatus.status === 'cancel'">{{$t('checkout_cancel_info')}}</p>



      <transition name="slide-fade">
        <img :src="image" v-if="image && !checkout.merchantLate">
        <img src="https://delicity.b-cdn.net/public/assets/gif/gif/crowd.gif" v-if="checkout.merchantLate">
      </transition>
      <span class="subtitle" v-if="checkout.currentStatus.status === 'delivered' && $te('checkout_subtitle_'+checkout.currentStatus.status+'_'+checkout.orderType)">{{$t('checkout_subtitle_'+checkout.currentStatus.status+'_'+checkout.orderType, {merchantName: checkout.merchant.name, courierName: checkout.courier ? checkout.courier.name : ''})}}</span>

    </div>

    <div v-show="showMap" class="map-container">
      <div ref="gmap" class="map"></div>
      <div v-if="showMap && checkout.courier" class="courier">
        <img :src="checkout.courier.avatar.path">
        <div class="courier-info">
          <span v-if="!checkout.courier.arrived">{{ $t('courier_is_comming', {courierName: checkout.courier.name}) }}</span>
          <span v-if="checkout.courier.arrived">{{ $t('courier_arrived', {courierName: checkout.courier.name}) }}</span>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import config from "@/config";

export default {
  data(){
    return {
      directionsService: null,
      directionsRenderer: null,
      map: {
        $map: null,
        $courierPosition: null,
      },
    }
  },
  computed: {
    ...mapGetters(['checkout']),
    showMap(){
      return this.checkout.currentStatus.status === 'delivery_ongoing' && this.checkout.courier;
    },
    image(){
      if(this.checkout.images.length === 0){
        return false;
      }
      return this.checkout.images[Math.floor(Math.random() * this.checkout.images.length)];
    },
  },
  methods: {
    ...mapActions([]),
    loadMap(){
      this.directionsService = new google.maps.DirectionsService();
      this.directionsRenderer = new google.maps.DirectionsRenderer({
        polylineOptions: {
          strokeColor: "#000"
        },
        suppressMarkers: true,
        suppressBicyclingLayer: true,
      });

      this.map.$map = new google.maps.Map(this.$refs.gmap, {
        zoom: 15,
        center: { lat: this.checkout.courier.position.lat, lng: this.checkout.courier.position.lng },
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: true,
        maxZoom: 18,
        mapTypeId: 'roadmap',
        styles: [{"featureType":"administrative","elementType":"geometry","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","stylers":[{"visibility":"off"}]},{"featureType":"poi.medical","stylers":[{"visibility":"off"}]},{"featureType":"poi.school","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","stylers":[{"visibility":"off"}]}]
      });
      this.directionsRenderer.setMap(this.map.$map);
/*
      this.map.$merchant = new google.maps.Marker({position: {lat: this.checkout.merchantPosition.lat, lng: this.checkout.merchantPosition.lng},
        icon: {url: 'https://delicity.b-cdn.net/public/assets/map_merchant.svg', anchor: {x: 15, y: 15}, size: {width: 30, height: 30}}, map: this.map.$map});
*/
      this.map.$client = new google.maps.Marker({position: {lat: this.checkout.clientPosition.lat, lng: this.checkout.clientPosition.lng},
        icon: {url: 'https://delicity.b-cdn.net/public/assets/map_client.svg', anchor: {x: 15, y: 15}, size: {width: 30, height: 30}}, map: this.map.$map});

      this.map.$courier = new google.maps.Marker({
        position:  { lat: this.checkout.courier.position.lat, lng: this.checkout.courier.position.lng },
        map: this.map.$map,
        icon: 'https://t.delicity.co/images/marker_bike.png'
      });

       var bounds = new google.maps.LatLngBounds();
       bounds.extend(this.map.$courier.position);
       bounds.extend(this.map.$client.position);
       this.map.$map.fitBounds(bounds);

      // if(localStorage.getItem('bearer')){
      //   Echo.private('courier.'+this.checkout.courier.id)
      //       .listen('CourierPosition', (data) => {
      //         this.animatedMove(this.map.$courier, .5, this.map.$courier.position, new google.maps.LatLng(data.courierPosition.lat, data.courierPosition.lng));
      //       });
      // }

    },
    animatedMove(marker, t, current, moveto) {
      var lat = current.lat();
      var lng = current.lng();

      var deltalat = (moveto.lat() - current.lat()) / 100;
      var deltalng = (moveto.lng() - current.lng()) / 100;

      var delay = 10 * t;
      for (var i = 0; i < 100; i++) {
        (function(ind) {
          setTimeout(
              function() {
                var lat = marker.position.lat();
                var lng = marker.position.lng();
                lat += deltalat;
                lng += deltalng;
                marker.setPosition(new google.maps.LatLng(lat, lng));
              }, delay * ind
          );
        })(i)
      }
    }
  },
  watch:{
    checkout(val, old){
      if(val.currentStatus.status === 'delivery_ongoing' && old.currentStatus.status !== 'delivery_ongoing' && this.checkout.courier){
        this.loadMap();
      }

      if(val.currentStatus.status === 'delivery_ongoing' && this.$route.params.shareToken){
        this.animatedMove(this.map.$courier, .5, this.map.$courier.position, new google.maps.LatLng(this.checkout.courier.position.lat, this.checkout.courier.position.lng));

        setTimeout(() => {
          let bounds = new google.maps.LatLngBounds();
          bounds.extend(this.map.$courier.position);
          bounds.extend(this.map.$client.position);
          this.map.$map.fitBounds(bounds);
        }, 600)
      }
    }
  },
  mounted() {
    if(this.checkout.currentStatus.status === 'delivery_ongoing' && this.checkout.courier){
      this.loadMap();
    }
  }
}
</script>
