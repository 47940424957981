<template>
  <div class="checkout-nav">
    <div class="head">
      <template v-if="checkout.currentStatus.status !== 'taken_preorder'">
        <div class="estimated" v-if="!checkout.courier || checkout.courier && !checkout.courier.arrived"  v-show="showNav">
          <div v-if="checkout.eta && !dontShowTime">
            <strong :key="checkout.eta">{{ checkout.eta }}</strong>
          </div>
          <div v-if="checkout.etp && !checkout.eta && !dontShowTime">
            <strong :key="checkout.etp">{{ checkout.etp }}</strong>
          </div>
          <div v-if="dontShowTime">
            <strong key="ended">{{$t('ready')}}</strong>
          </div>
        </div>
        <div class="estimated" v-if="checkout.courier && checkout.courier.arrived"  v-show="showNav">
          <strong>{{ $t('courier_is_arrived') }}</strong>
        </div>
      </template>
      <template v-else >
        <div class="estimated estimated-preorder">
          <template v-if="checkout.preorder.today">
            <strong v-if="checkout.orderType === 'delivery'">{{$t('checkout_preorder_today_arrival_delivery', {date:checkout.preorder.date})}}</strong>
            <strong v-if="checkout.orderType === 'delivery'">{{$t('checkout_preorder_today_arrival_second_delivery', {hour:checkout.preorder.dateHour, max:checkout.preorder.dateHourMax})}}</strong>
            <strong v-if="checkout.orderType === 'pickup'">{{$t('checkout_preorder_today_arrival_pickup', {date:checkout.preorder.date})}}</strong>
            <strong v-if="checkout.orderType === 'pickup'">{{$t('checkout_preorder_today_arrival_second_pickup', {hour:checkout.preorder.dateHour})}}</strong>
          </template>
          <template v-else>
            <strong v-if="checkout.orderType === 'delivery'">{{$t('checkout_preorder_arrival_delivery', {date:checkout.preorder.date})}}</strong>
            <strong v-if="checkout.orderType === 'delivery'">{{$t('checkout_preorder_arrival_second_delivery', {hour:checkout.preorder.dateHour, max:checkout.preorder.dateHourMax})}}</strong>
            <strong v-if="checkout.orderType === 'pickup'">{{$t('checkout_preorder_arrival_pickup', {date:checkout.preorder.date})}}</strong>
            <strong v-if="checkout.orderType === 'pickup'">{{$t('checkout_preorder_arrival_second_pickup', {hour:checkout.preorder.dateHour})}}</strong>
          </template>
        </div>
      </template>
      <div class="actions" :class="{'justify-content-center': !showNav}">
        <input type="text" class="form-control share" v-model="shareLink" ref="pageLink" v-if="!shareBtn">
        <ui-button color="default" icon="share" ref="shareBtn" @click="share" v-if="shareBtn">{{$t('share')}}</ui-button>
        <ui-button color="green" @click="$root.$emit('openReceipt')" icon="receipt">{{ $t('checkout_see_receipt') }}</ui-button>
        <ui-button color="clear" @click="$root.$emit('openInformations')">{{ $t('more_informations') }}</ui-button>
      </div>
    </div>
    <template v-if="checkout.selfDelivery && checkout.orderType === 'delivery'">
      <div class="status" v-if="!checkout.courier || checkout.courier && !checkout.courier.arrived" v-show="showNav">
        <strong>Le restaurant vous livre la commande, le suivi du livreur n'est pas disponible.</strong>
        <span v-if="checkout.maxArrival && checkout.orderType === 'delivery'">{{ $t('checkout_max_eta', {time: checkout.maxArrival}) }}</span>
      </div>
    </template>
    <template v-else>
      <div class="status" v-if="!checkout.courier || checkout.courier && !checkout.courier.arrived" v-show="showNav">
        <strong v-if="$te('checkout_subtitle_'+checkout.currentStatus.status+'_'+checkout.orderType)">{{$t('checkout_subtitle_'+checkout.currentStatus.status+'_'+checkout.orderType, {merchantName: checkout.merchant.name, courierName: checkout.courier ? checkout.courier.name : 'Le livreur'})}}</strong>
        <strong>Commande {{checkout.orderRef}}</strong>
        <span v-if="checkout.maxArrival && checkout.orderType === 'delivery'">{{ $t('checkout_max_eta', {time: checkout.maxArrival}) }}</span>
      </div>
    </template>
    <div class="status" v-show="showNav" v-if="checkout.courier && checkout.courier.arrived">
      <strong >{{$t('courier_is_arrived_text', {courierName: checkout.courier ? checkout.courier.name : $t('courier')})}}</strong>
    </div>
    <div class="bar-container" v-show="showNav"><div class="bar" :style="{width: barWidth + '%'}"></div></div>

    <div class="alert alert-info mb-0 border-0" v-if="checkout.orderType === 'on_site_counter' && checkout.currentStatus.id < 30">{{ $t('on_site_counter_checkout_message') }}</div>

    <div class="alert alert-rush border-0 mb-0" v-if="checkout.merchantLate">
      <h4>{{$t('checkout_nav_rush_title')}}</h4>
      <p>{{$t('checkout_nav_rush_description')}} <a :href="'tel:'+checkout.merchant.phone" v-if="checkout.merchant.phone" class="phone">{{$t('checkout_nav_rush_cta')}}</a></p>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import tippy from 'tippy.js/esm';
import 'tippy.js/themes/translucent.css'
import 'tippy.js/index.css'

export default {
  data(){
    return {
      barWidth: 50,
      shareBtn: true
    }
  },
  computed: {
    ...mapGetters(['checkout']),
    showNav(){
      return this.checkout.currentStatus.status !== 'delivered';
    },
    shareLink(){
      return document.location.href;
    },
    dontShowTime(){
      return this.checkout.orderType === 'on_site_counter' && this.checkout.currentStatus.id > 20;
    }
  },
  methods: {
    ...mapActions(['getShareToken']),
    getBarWidth(){
      let totalStatus = this.checkout.statues.length;
      let current = this.checkout.statues.findIndex(s => s.id === this.checkout.currentStatus.id) + 1;
      this.barWidth = current * 100 / totalStatus;
    },
    share(){
      this.shareBtn = false;
      this.$nextTick(() => {
        this.$refs.pageLink.select();
        document.execCommand('copy');

          tippy(this.$refs.pageLink, {
            content: this.$t('copied'),
            placement: 'top',
            arrow: 'default',
            trigger: 'manual',
            theme: 'translucent',
            zIndex: 100
          }).show();
          if(this.$refs.pageLink){
            this.$refs.pageLink.focus();
          }

      });
    }
  },
  mounted() {
    this.getBarWidth();
  },
  watch:{
    checkout:{
      deep: true,
      handler(val){
        this.getBarWidth();
      }
    }
  }
}
</script>


